@use '@angular/material' as mat;

@include mat.core();
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'theme-fem-readup-red';

.fem-read-red-theme {
  @include mat.all-component-themes($fem-readup-red-theme);
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900') format("truetype");
  font-display: swap;
}

html {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0; /* remove scrollbar space */
  height: 0; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}

body {
  height: 100%;
  color: mat.get-color-from-palette(mat.$grey-palette, 900);
  font-family: 'Outfit', sans-serif;
  margin: 0;

  display: flex;
  flex-direction: column;
}

::ng-deep .mat-toolbar-single-row {
  padding: 0 !important;
}

::ng-deep .mat-button {
  min-width: 0 !important;
}

.lazyload,
.lazyloading {
  opacity: 0.7;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 2000ms;
}

.mat-card {
  background: mat.get-color-from-palette($fem-readup-red-primary, 50);
  box-shadow: none !important;
  border-radius: unset;
  font-family: 'Outfit' !important;

  .mat-card-title {
    color: mat.get-color-from-palette($fem-readup-red-primary, 500);
  }
  .mat-card-subtitle {
    color: mat.get-color-from-palette($fem-readup-red-primary, 500);
  }
  .mat-card-content {
    color: mat.get-color-from-palette($fem-readup-red-primary, 500);
  }
}
.mat-card:hover, mat-card:active {
  background: mat.get-color-from-palette($fem-readup-red-primary, 500);
  .mat-card-title {
    color: mat.get-color-from-palette($fem-readup-red-primary, 50);
  }
  .mat-card-subtitle {
    color: mat.get-color-from-palette($fem-readup-red-primary, 50);
  }
  .mat-card-subtitle {
    color: mat.get-color-from-palette($fem-readup-red-primary, 50);
  }
  .mat-card-content {
    color: mat.get-color-from-palette($fem-readup-red-primary, 50);
  }
  .event-category {
    color: mat.get-color-from-palette($fem-readup-red-primary, 50);
    font-weight: 600;
    margin-bottom: 12px;
  }
}

.mat-chip {
  border-color: mat.get-color-from-palette($fem-readup-red-primary, 50) !important;
}
.mat-chip:hover, mat-chip:active {
  background: mat.get-color-from-palette($fem-readup-red-primary, 500) !important;
  color: mat.get-contrast-color-from-palette($fem-readup-red-primary, 400) !important;
  border: mat.get-color-from-palette($fem-readup-red-primary, 500) !important;
}




